﻿import React, { lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import App from '../components/app/app';
import { getMarket } from '../i18n';

const Startpage = lazy(() => import('./startpage'));
const Search = lazy(() => import('./search/components/search'));
const SearchHistory = lazy(() => import('./search/components/search-history'));
const Item = lazy(() => import('./item/item'));
const Articles = lazy(() => import('./articles'));
const Article = lazy(() => import('./article'));
const PopulateAssignment = lazy(() => import('../routes/assignments/PopulateAssignment'));
const PublishedAssignment = lazy(() => import('../routes/assignments/PublishedAssignment'));
const CreateAssignment = lazy(() => import('../routes/assignments/CreateAssignment'));
const CarValuation = lazy(() => import('../routes/car-valuation'));
const CarValuationPreview = lazy(() => import('../routes/car-valuation-preview'));
const RetailerList = lazy(() => import('./retailers'));
const Retailer = lazy(() => import('./retailer'));
const Faq = lazy(() => import('./customer-service/faq'));
const CustomerService = lazy(() => import('./customer-service'));
const ContentPageSelector = lazy(() => import('./contentpages/components/content-page-selector'));
const SharedSubscriptionList = lazy(() => import('./shared/subscription-list'));
const Profile = lazy(() => import('./profile/profile'));
const NamespacedAdvertisement = lazy(() => import('./list/namespaced-advertisement'));
const SearchSubscription = lazy(() => import('./list/search-subscription'));
const LiveShopping = lazy(() => import('./live-shopping'));
const LiveShoppingSingle = lazy(() => import('./live-shopping/LiveShoppingSingle'));
const AuthorPage = lazy(() => import('./author'));
const ArticleSearch = lazy(() => import('./articles/ArticleSearch'));
const ErrorView = lazy(() => import('./error'));

export const routeElementsSEObject: RouteObject[] = [
  {
    path: '/',
    errorElement: <ErrorView />,
    element: <App market="SE" />,
    children: [
      {
        index: true,
        element: <Startpage />,
      },
      {
        path: 'sok',
        children: [
          { index: true, element: <Search /> },
          { path: 'historik', element: <SearchHistory /> },
          { path: '*', element: <Search /> },
        ],
      },
      {
        path: 'salj-till-handlare',
        children: [
          { index: true, element: <CreateAssignment /> },
          { path: 'publicerad', element: <PublishedAssignment /> },
          { path: 'skapa/:id?', element: <PopulateAssignment /> },
        ],
      },
      {
        path: 'vardera-bil',
        children: [
          { index: true, element: <CarValuation /> },
          { path: ':registrationNumber', element: <CarValuationPreview /> },
          { path: ':registrationNumber/:odometerReadingValue', element: <CarValuationPreview /> },
        ],
      },
      {
        path: 'objekt/:id/:description?',
        element: <Item />,
      },
      {
        path: 'artiklar',
        children: [
          { index: true, element: <Articles /> },
          { path: 'sok', element: <ArticleSearch /> },
          { path: ':category/:slug', element: <Article /> },
          { path: ':category?', element: <Articles /> },
        ],
      },
      {
        path: 'aterforsaljare',
        children: [
          { index: true, element: <RetailerList /> },
          { path: ':slug', element: <Retailer /> },
        ],
      },
      {
        path: 'listor/sparade-annonser/:id',
        element: <NamespacedAdvertisement />,
      },
      {
        path: 'listor/bevakade-sokningar/:id',
        element: <SearchSubscription />,
      },
      {
        path: 'profil',
        children: [
          { index: true, element: <Profile viewName="profile" /> },
          {
            path: 'mina-bilar',
            children: [
              { index: true, element: <Profile viewName="my-cars" /> },
              { path: ':id', element: <Profile viewName="my-cars" /> },
            ],
          },
          { path: 'bevakningar', element: <Profile viewName="subscriptions" /> },
          {
            path: 'meddelanden',
            children: [
              { index: true, element: <Profile viewName="conversations" /> },
              { path: ':id', element: <Profile viewName="conversations" /> },
            ],
          },
          { path: 'konto', element: <Profile viewName="account" /> },
          { path: 'mina-annonser', element: <Profile viewName="advertisements" /> },
        ],
      },
      {
        path: 'delat/bevakningslista/:id',
        element: <SharedSubscriptionList />,
      },
      {
        path: 'kundservice',
        children: [
          { index: true, element: <CustomerService /> },
          { path: ':category/:slug?', element: <Faq /> },
        ],
      },
      {
        path: 'liveshopping',
        children: [
          {
            index: true,
            element: <LiveShopping />,
          },
          {
            path: ':slug',
            element: <LiveShoppingSingle />,
          },
        ],
      },
      {
        path: 'skribenter/:slug',
        element: <AuthorPage />,
      },
      {
        path: ':parent/:child',
        element: <ContentPageSelector />,
      },
      {
        path: ':parent',
        element: <ContentPageSelector />,
      },
    ],
  },
];

export const routeElementsNOObject: RouteObject[] = [
  {
    path: '/',
    errorElement: <ErrorView />,
    element: <App market="NO" />,
    children: [
      {
        index: true,
        element: <Startpage />,
      },
      {
        path: 'sok',
        children: [
          { index: true, element: <Search /> },
          { path: 'historik', element: <SearchHistory /> },
          { path: '*', element: <Search /> },
        ],
      },
      {
        path: 'selg-til-forhandler',
        children: [
          { index: true, element: <CreateAssignment /> },
          { path: 'publisert', element: <PublishedAssignment /> },
          { path: 'opprett/:id?', element: <PopulateAssignment /> },
        ],
      },
      {
        path: 'verdivurdere-bil',
        children: [
          { index: true, element: <CarValuation /> },
          { path: ':registrationNumber', element: <CarValuationPreview /> },
          { path: ':registrationNumber/:odometerReadingValue', element: <CarValuationPreview /> },
        ],
      },
      {
        path: 'objekt/:id/:description?',
        element: <Item />,
      },
      {
        path: 'artikler',
        children: [
          { index: true, element: <Articles /> },
          { path: 'sok', element: <ArticleSearch /> },
          { path: ':category/:slug', element: <Article /> },
          { path: ':category?', element: <Articles /> },
        ],
      },
      {
        path: 'forhandler',
        children: [
          { index: true, element: <RetailerList /> },
          { path: ':slug', element: <Retailer /> },
        ],
      },
      {
        path: 'lislistertor/lagrede-annonser/:id',
        element: <NamespacedAdvertisement />,
      },
      {
        path: 'lister/overvaket-sok/:id',
        element: <SearchSubscription />,
      },
      {
        path: 'profil',
        children: [
          { index: true, element: <Profile viewName="profile" /> },
          {
            path: 'mine-biler',
            children: [
              { index: true, element: <Profile viewName="my-cars" /> },
              { path: ':id', element: <Profile viewName="my-cars" /> },
            ],
          },
          { path: 'overvakninger', element: <Profile viewName="subscriptions" /> },
          {
            path: 'meldinger',
            children: [
              { index: true, element: <Profile viewName="conversations" /> },
              { path: ':id', element: <Profile viewName="conversations" /> },
            ],
          },
          { path: 'konto', element: <Profile viewName="account" /> },
          { path: 'mine-salgsforesporseler', element: <Profile viewName="advertisements" /> },
        ],
      },
      {
        path: 'delat/bevakningslista/:id',
        element: <SharedSubscriptionList />,
      },
      {
        path: 'kundeservice',
        children: [
          { index: true, element: <CustomerService /> },
          { path: ':category', element: <Faq /> },
          { path: ':category/:slug', element: <Faq /> },
        ],
      },
      {
        path: 'forfattere/:slug',
        element: <AuthorPage />,
      },
      {
        path: ':parent/:child',
        element: <ContentPageSelector />,
      },
      {
        path: ':parent',
        element: <ContentPageSelector />,
      },
    ],
  },
];

const routerProvicerFuture = {
  v7_fetcherPersist: true,
  v7_normalizeFormMethod: true,
  v7_partialHydration: true,
  v7_relativeSplatPath: true,
  v7_skipActionErrorRevalidation: true,
};

const resolveRoutes = (marketCode: string) => {
  switch (marketCode) {
    case 'NO':
      return routeElementsNOObject;
    default:
      return routeElementsSEObject;
  }
};

export const Routes = () => {
  const { marketCode } = getMarket();
  const routes = resolveRoutes(marketCode);
  const router = createBrowserRouter(routes, {
    future: routerProvicerFuture,
  });
  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
};
