import { createGlobalStyle } from 'styled-components';
import { customProperties } from './custom-properties';
import type { Theme } from './theme';

export default createGlobalStyle<{ theme?: Theme }>`
    ${customProperties};

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    html {
        font-size: 100%;
        -webkit-text-size-adjust: 100%;
    }

    body {
        margin: 0;
        background-color: var(--c-bg-main);
        color: var(--c-text-main);
        font-size: var(--font-size-regular);
        font-family: var(--font-family);
        font-weight: var(--font-weight-body);
        line-height: ${(props) => props.theme.font.lineHeight.main};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    b,
    strong {
        font-weight: var(--font-weight-strong);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 16px;
    }

    a {
        text-decoration: none;
        color: currentColor;

        img {
            border: 0;
        }
    }

    input,
    textarea,
    select {
        font-family: var(--font-family);
    }

    input,
    textarea,
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }

    a,
    area,
    button,
    [role='button'],
    input,
    label,
    select,
    summary,
    textarea {
    -ms-touch-action: manipulation;
        touch-action: manipulation;
    }

    // Class used to visuallt hide forbidden information
    .hidden-information {
        filter: blur(0.2em);
        background-color: var(--c-bg-alt);
        color: transparent;
        border-radius: 3px;
        cursor: default;
    }
`;
